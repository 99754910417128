import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ComponentTextblock1 from "../components/component-textblock-1";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const PageRecruit = ({ data, location, serverData }) => {
  const intl = useIntl();
  const posts = data.allContentfulSalesDocuments.edges;

  const [pswd, setPswd] = useState({password: ""});
  const [authState, setAuthState] = React.useState("");

  const isBrowser = () => typeof window !== "undefined"

  const getUser = async () => {
    isBrowser() && window.localStorage.getItem("AuthUser") == "Authorized"
      ? setAuthState("authorized")
      : setAuthState("unauthorized")
  };

  useEffect(() => {
    getUser()
  }, [authState]);

  async function handleChange(e) {
    pswd[e.target.name] = e.target.value;
    setPswd({ ...pswd });
  }

  async function onSubmit(e) {
    e.preventDefault()
    if(pswd["password"] == "cyberdyne-eu"){
      window.localStorage.setItem("AuthUser", "Authorized")
      window.location.reload();
    }else{
      window.localStorage.setItem("AuthUser", "Unauthorized")
      window.location.reload();
    }
  }

  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Sales Documents`
            : `Sales Documents`
        }
        description={
          intl.locale === "en"
            ? `Sales Documents`
            : `Sales Documents`
        }
      />
      <section className="sect bg-bge">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en" ? `Sales Documents` : `Sales Documents`
            }
            //subtitle={intl.locale === "en" ? `SampleSampleSample` : `SampleSampleSample`}
            //content={
            //  intl.locale === "en"
            //    ? `SampleSampleSample`
            //    : `SampleSampleSample`
            //}
          />
          {authState != "authorized" ?
          <div className="container-bg-right">
            <h3 className="inquiry_title mb-8">
              {intl.locale === "en" ? `Pasword` : `Pasword`}
            </h3>
            {/*authState*/}
            {/*JSON.stringify(posts)*/}
            <form
              onSubmit={onSubmit}
              method="POST"
              action="/api/form"
              className="formWrapper"
            >
              <input
                type="text"
                name="password"
                className="form-control mb-4 form-control-password"
                value={pswd["password"] || ``}
                onChange={handleChange}
              />
              <input
                type="submit"
                value={intl.locale === "en" ? `Send` : `Absenden`}
                name="submit"
                className="link-button link-button-document"
              />
            </form>
          </div>
          :
          <div>
            <ul className="documents-list">
              {posts && posts.map(({ node: post }) => {
                return (
                  <li>
                    <div className="documents-list-right">
                      <p className="title">{post.title}</p>
                      <p className="description">{post.description.childMarkdownRemark.rawMarkdownBody}</p>
                    </div>
                    <div className="documents-list-left">
                      {post.mediafileEn &&
                      <a
                        href={post.mediafileEn.url}
                        //download
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFileArrowDown} />
                        <span>EN</span>
                      </a>
                      }
                      {post.mediafileDe &&
                      <a
                        href={post.mediafileDe.url}
                        //download
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFileArrowDown} />
                        <span>DE</span>
                      </a>
                      }
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          }
        </div>
      </section>
    </Layout>
  );
};

export default PageRecruit;

export async function getServerData() {
  //try {
  //  const res = await fetch(`https://dog.ceo/api/breeds/image/random`)

  //  if (!res.ok) {
  //    throw new Error(`Response failed`)
  //  }

  //  return {
  //    props: await res.json(),
  //  }
  //} catch (error) {
  //  return {
  //    status: 500,
  //    headers: {},
  //    props: {}
  //  }
  //}
  return {
    status: 200,
    headers: {},
    props: {}
  }
}

export const pageQuery = graphql`
  query {
    allContentfulSalesDocuments(
      sort: {
        order: [DESC, ASC], 
        fields: [sortByDate, contentful_id]
      }
      filter: {
        title: { ne: "do-not-delete-sample" }
        node_locale: { eq: "en" }
      }
    ) {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          mediafileEn {
            url
          }
          mediafileDe {
            url
          }
        }
      }
    }
  }
`;
